<template>
  <div class="vm-news-wrap">
    <div class="featured-news-wrap">
      <NetworkStatus/>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import NetworkStatus from '@/components/Home/NetworkStatus'

export default {
  name: 'VumaNetworkStatus',
  components: {
    NetworkStatus,
  },
  data: () => ({
    isShop: false,
  }),
  mounted () {
    $('html').css('overflow', 'auto')
    $('.vuma-menu, .menu-overlay').removeClass('menu-show')
    this.scrollTop()
  },
  methods: {
    scrollTop () {
      var target = 'html, body'
      var $target = $(target)

      $('html, body').stop().animate({
        'scrollTop': $target.offset().top,
      }, 500, 'swing')
    },
  },
  metaInfo () {
    return {
      title: 'Vuma Network Status | Our home for real-time and historical data on system performance',
      meta: [
        {
          name: 'description',
          content: 'Our home for real-time and historical data on system performance.',
        },
        { property: 'og:title', content: 'Vuma Network Status | Our home for real-time and historical data on system performance' },
        { property: 'og:site_name', content: 'Vumatel' },
        { property: 'og:type', content: 'website' },
      ],
    }
  },
}
</script>

<style lang="scss">
.status-page {
  padding-top: 75px;

  .theme--light.v-application {
    background-color: rgba(166, 166, 166, 0.25);
  }

  .vuma-header {
    top: 0;
    background: #fff !important;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;

    a.vm-logo .vuma-logo-white {
      display: none;
    }

    a.vm-logo .vuma-logo-pink {
      display: inline-block;
    }

    .top-nav .v-btn--text {
      color: #EE008C;
    }

    &.fixed-top {
      background: #ff008e !important;

      .top-nav .v-btn--text {
        color: #fff;
      }

      a.vm-logo .vuma-logo-white {
        display: inline-block;
      }

      a.vm-logo .vuma-logo-pink {
        display: none;
      }
    }

    #top-coverage {
      display: none;
    }
  }

  #read-more-news {
    display: none;
  }
}
</style>