<template>
  <div class="vm-section vm-core d-flex align-center">
    <v-container class="p-relative">
      <v-row class="justify-center text-center">
        <v-col class="xs-12 sm-5 md-3 mt-10 mb-10">
          <h1>Vuma Network Status</h1>
          <p>
            Welcome to Vumatel&#39;s home for real-time information on network
            related events.
          </p>
        </v-col>
      </v-row>

      <v-row>
        <v-col md="3" sm="12" xl="3" offset-md="1" offset-xl="2">
          <v-card class="">
            <v-toolbar flat dark>
              <v-toolbar-title>Filter results</v-toolbar-title>
            </v-toolbar>

            <v-card-text>
              <v-select
                v-model="status"
                @change="filterEventType()"
                :items="eventStatuses"
                :menu-props="{ maxHeight: '400' }"
                outlined
                label="Filter Events By Status"
                persistent-hint
              ></v-select>

              <v-select
                :items="cities"
                :loading="loading"
                :menu-props="{ maxHeight: '400' }"
                @change="getNetworkEvents()"
                v-model="selectedCities"
                label="Filter Events By City"
                multiple
                outlined
                clearable
                persistent-hint
              ></v-select>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col md="6" sm="12" xl="5">
          <v-row no-gutters class="justify-center mb-5" v-if="loading">
            <v-card outlined style="width: 100%">
              <v-card-subtitle>
                <v-skeleton-loader class="mb-2" max-width="250" type="text" />

                <v-skeleton-loader
                  max-width="150"
                  type="text"
                ></v-skeleton-loader>
              </v-card-subtitle>
              <v-card-text>
                <hr
                  style="border: 0.05rem solid #e6e6e6; margin-bottom: 20px"
                />
                <v-skeleton-loader
                  v-bind="attrs"
                  type="text@3, text@2"
                ></v-skeleton-loader>
              </v-card-text>
            </v-card>
          </v-row>
          <v-row
            no-gutters
            class="justify-center mb-5"
            v-else
            v-for="networkEvent in networkEvents"
            :key="networkEvent.id"
          >
            <v-card width="100%" outlined>
              <v-card-title
                class="justify-space-between"
                style="font-size: 18px"
              >
                <strong style="word-break: keep-all;">{{ cardTitle(networkEvent) }}</strong>
                <v-chip class="ma-2" color="indigo" small outlined style="margin-left: auto !important;">
                  <v-icon small left>mdi-info</v-icon>
                  <span v-if="networkEvent.event_type == 'CHANGE_MANAGEMENT'">
                    Scheduled Maintenance</span
                  >
                  <span v-else>Network Incident</span>
                </v-chip>
              </v-card-title>
              <v-card-subtitle v-if="status == 'Historical'"
                >{{ networkEvent.event_end_date.split("T")[0] }}
                {{ networkEvent.event_end_date.split("T")[1].split("+")[0] }}
                SAST</v-card-subtitle
              >
              <v-card-subtitle v-else
                >{{ networkEvent.event_start_date.split("T")[0] }}
                {{ networkEvent.event_start_date.split("T")[1].split("+")[0] }}
                SAST</v-card-subtitle
              >
              <v-card-text>
                <hr
                  style="border: 0.05rem solid #e6e6e6; margin-bottom: 20px"
                />
                {{ networkEvent.website_description }}
              </v-card-text>
            </v-card>
          </v-row>

          <v-row
            no-gutters
            class="justify-center mb-5"
            v-if="!loading && !loadingFailed && networkEvents.length == 0"
          >
            <v-col>
              <div class="text-center">
                <v-card>
                  <v-card-text>
                    <v-icon color="green" class="mt-16" x-large>
                      mdi-checkbox-marked-circle
                    </v-icon>
                    <h3 class="mt-10 mb-3">Everything is looking good</h3>
                    <h4 class="mb-9">
                      There are no problems to report at the moment
                    </h4>
                  </v-card-text>
                </v-card>
              </div>
            </v-col>
          </v-row>

          <v-row no-gutters class="justify-center mb-5" v-if="loadingFailed">
            <v-col>
              <div class="text-center">
                <v-card>
                  <v-card-text>
                    <v-icon color="red" class="mt-16" x-large>
                      mdi-alert-circle-outline
                    </v-icon>
                    <h3 class="mt-10 mb-3">
                      <strong>Something went wrong...</strong>
                    </h3>
                    <h4 class="mb-9">
                      There was a problem and we couldn't load the network
                      incidents. <br />
                      Please check your internet connection.
                    </h4>
                  </v-card-text>
                </v-card>
              </div>
            </v-col>
          </v-row>

          <v-row
            v-if="pageCount > 1 && !loadingFailed"
            class="justify-center"
            no-gutters
          >
            <v-pagination
              v-model="page"
              :length="pageCount"
              @input="getNetworkEvents()"
            />
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "NetworkStatus",
  data() {
    return {
      baseURL: process.env.VUE_APP_PANGEA_BASE_URL + "/support/website-events",
      loading: false,
      loadingFailed: false,
      selectedCities: [],
      networkEvents: [],
      totalNetworkEvents: 0,
      page: 1,
      pageSize: 5,
      cities: [],
      status: "Open",
      eventTypeSortMap: {
        Open: "-event_start_date",
        Historical: "-event_end_date",
        Future: "-event_start_date",
      },
      eventStatuses: ["Open", "Historical", "Future"],
    };
  },
  computed: {
    showFilters() {
      return this.networkEvents.length > 1;
    },
    pageCount() {
      return this.totalNetworkEvents
        ? Math.ceil(this.totalNetworkEvents / this.pageSize)
        : 0;
    },
  },
  mounted() {
    this.getNetworkEvents();
  },
  methods: {
    cardTitle({ nwi_name, suburb, city }) {
      const incidentName = nwi_name || suburb || "Network Event";
      return `${incidentName} | ${city}`;
    },
    filterEventType() {
      this.page = 1;
      this.getNetworkEvents();
    },
    async getNetworkEvents() {
      try {
        this.loading = true;
        this.loadingFailed = false;
        this.networkEvents = [];

        const params = {
          type: this.status.toLowerCase(),
          ordering: this.eventTypeSortMap[this.status],
          page_size: this.pageSize,
          page: this.page,
        };
        if (this.selectedCities.length) {
          params.city__contains = this.selectedCities.join(",")
        }
        const headers = { 'x-target-backend': 'salesforce' }
        const response = await this.axios.get(`${this.baseURL}/`, { params, headers });
        this.networkEvents = response.data.events.results;
        this.cities = response.data.cities;
        this.totalNetworkEvents = response.data.events.total_records;
      } catch (err) {
        this.loadingFailed = true;
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
